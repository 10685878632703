import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { RiEditLine } from '@remixicon/react';
import { App, PaginationProps, TablePaginationConfig } from 'antd';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { DeleteCatalogRecordUi } from 'features/catalogs/CatalogRecords/DeleteCatalogRecord';
import {
	CatalogItemResponse,
	GetCatalogAttributeDeclarationsApiResponse,
	ItemDto,
	useLazyGetCatalogAttributeDeclarationsQuery,
	useLazyGetCatalogItemQuery,
	useLazyGetCatalogItemsQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { useLazyGetSearchResultQuery } from 'shared/api/generatedApi/searchApi';
import { errorHelper } from 'shared/helpers';
import { CellTypesEnum, CellValueParser } from 'shared/helpers/CellValueParser';
import { ItemValuesType } from 'shared/helpers/types';
import { useAppDispatch, useAppSelector, useTypedTranslation } from 'shared/hooks';
import { useDebounce } from 'shared/hooks/useDebounce';
import { ICatalogRecordsColumns } from 'shared/types';
import { Chip, DropdownLink, setCatalogRecordsOriginalColumns } from 'shared/ui';
import AppDropdown from 'shared/ui/components/AppDropdown';
import { ChipStatus } from 'shared/ui/components/Chip/chipStylehelper';
import { ColumnsAdjustmentWidget } from 'shared/ui/components/ColumnsAdjustmentWidget';
import i18next from '../../../i18n';
import { setAttributeDeclarationsList } from '../attributeDeclarations';
import { removeCatalogRecords, setCatalogRecords, setPagination } from './catalogRecords.store';

export type ICatalogRecord = {
	id: string;
	recStatus: JSX.Element;
	menu: JSX.Element;
	key: string;
};
export type ICatalogRecords = Array<ICatalogRecord>;

export interface IRecordValue {
	[key: string]: {
		attributeId: string;
		attributeName: string;
		value: ItemValuesType;
	};
}

export interface IMappedRecordValue {
	[key: string]: string | JSX.Element;
}

export type ItemStatusDto = 'NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE';

export const chipTitle = (str: string): string => {
	switch (str) {
		case 'NORMALIZED':
			return i18next.t('common.statuses.normalized');

		case 'NON_NORMALIZED':
			return i18next.t('common.statuses.nonNormalized');

		case 'BLOCKED':
			return i18next.t('common.statuses.blocked');

		case 'DELETED':
			return i18next.t('common.statuses.deleted');

		case 'DUPLICATE':
			return i18next.t('common.statuses.dupe');

		default:
			'';
	}
};

const tablePaginationShowTotal = (total) => `Всего: ${total}`;

export const useRecordsDtoToTableMapper = () => {
	const navigate = useNavigate();
	const { t } = useTypedTranslation();

	const { attributeDeclarationsList } = useAppSelector(
		(state) => state.entities.catalogs.attributeDeclarationsList
	);

	const [getItem, { isFetching: isItemLoading }] = useLazyGetCatalogItemQuery();

	const { notification } = App.useApp();

	const convertValues = async (values: IRecordValue): Promise<IMappedRecordValue> => {
		const result = {};

		if (values) {
			await Promise.all(
				Object.keys(values).map(async (id) => {
					const declaration = attributeDeclarationsList?.find((decl) => decl.id === id);
					if (declaration) {
						if (
							declaration.attribute.type === CellTypesEnum.RELATION &&
							values[id].value
						) {
							await getItem({ id: values[id].value as unknown as string })
								.unwrap()
								.then((res) => {
									result[values[id].attributeId] = CellValueParser(
										res.displayName.length > 0
											? res.displayName
											: t((l) => l.common.defaultNames.emptyName),
										declaration.attribute.type as CellTypesEnum
									);
								})
								.catch((err) =>
									errorHelper(
										t((l) => l.catalogs.records.recordsRelationsErr),
										err,
										notification
									)
								);
						} else {
							result[values[id].attributeId] = CellValueParser(
								values[id].value,
								declaration.attribute.type as CellTypesEnum
							);
						}
					}
				})
			);
		}

		return result;
	};

	const mapRecordsDtoToTable = async (recordsDto: ItemDto[] | null): Promise<ICatalogRecords> => {
		if (!recordsDto) return null;
		return await Promise.all(
			recordsDto.map(async (item) => ({
				id: item.id,
				key: item.id,
				catalogId: item.catalogId,
				recStatus: (
					<Chip status={item.status.toLowerCase() as ChipStatus}>
						{chipTitle(item.status)}
					</Chip>
				),
				displayName: item.displayName,
				menu: (
					<AppDropdown
						items={[
							{
								key: '0',
								label: <DeleteCatalogRecordUi recordId={item.id} />,
							},
							{
								key: '1',
								label: (
									<DropdownLink
										icon={<RiEditLine size={16} />}
										title={t((l) => l.common.buttons.edit)}
										callback={() =>
											navigate(
												`${window.location.pathname}/record/${item.id}`
											)
										}
									/>
								),
							},
						]}
					/>
				),
				...(await convertValues(item?.values as IRecordValue)),
			}))
		);
	};

	return {
		mapRecordsDtoToTable,
		isItemLoading,
	};
};

export const useCatalogRecords = () => {
	const { t } = useTypedTranslation();

	const [catalogRecordsColumns, setCatalogRecordsColumns] = useState<ICatalogRecordsColumns>([]);
	const [catalogRecordError, setCatalogRecordError] = useState<
		FetchBaseQueryError | SerializedError
	>(null);

	const { catalogGroupId } = useParams();
	const { pathname } = useLocation();

	const [isSubordinatedCatalogsRecordsShown, setIsSubordinatedCatalogsRecordsShown] =
		useState<boolean>(
			JSON.parse(localStorage.getItem(pathname))?.isSubordinatedCatalogsRecordsShown
		);

	const [fetchGetItems, { error: fetchGetItemsError, isFetching: isGetItemsLoading }] =
		useLazyGetCatalogItemsQuery();
	const [
		fetchGetAttributeDeclarations,
		{ error: fetchGetAttributeDeclarationsError, isFetching: isDeclarationsLoading },
	] = useLazyGetCatalogAttributeDeclarationsQuery();

	const [getSearchResult, { isFetching: isSearchItemsLoading }] = useLazyGetSearchResultQuery();

	const { notification } = App.useApp();
	const dispatch = useAppDispatch();
	const { catalogRecords, pagination } = useAppSelector(
		(state) => state.entities.catalogs.catalogRecords
	);

	const ajustedColumns = useAppSelector((state) => state.shared.ui.catalogRecordsAjustedColumns);

	const [searchParams, setSearchParams] = useSearchParams();

	const searchHandler = useDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
		searchParams.set('searchValue', e.target.value);
		setSearchParams(searchParams);
	}, 1000);

	const fetchSearch = (searchValue: string) => {
		getSearchResult({ entityType: 'item', textRequest: searchValue, parentId: catalogGroupId })
			.unwrap()
			.then((res) => {
				dispatch(setCatalogRecords(res as ItemDto[]));
				dispatch(
					setPagination({
						...pagination,
						total: res.length,
					})
				);
			})
			.catch((err) => {
				errorHelper(
					t((l) => l.catalogs.records.recordsListErr),
					err,
					notification
				);
				setCatalogRecordError(err);
			});
	};

	const fetchItems = async (
		catalogId: string,
		ids?: Array<string>,
		limit: number = 50,
		page: number = 1,
		includeSubCatalogs?: boolean
	): Promise<Array<CatalogItemResponse> | Error> => {
		return fetchGetItems({ catalogIds: [catalogId], ids, limit, page, includeSubCatalogs })
			.unwrap()
			.then((res) => {
				dispatch(
					setPagination({
						...pagination,
						current: res.meta.page,
						pageSize: res.meta.limit,
						total: res.meta.total,
					})
				);
				return res.data;
			})
			.catch((e) => {
				return e;
			});
	};

	useEffect(() => {
		getItems(catalogGroupId);
	}, [searchParams.get('searchValue')]);

	const getAttributeDeclarations = async (catalogIds: string[]) => {
		let attributeDeclarations: GetCatalogAttributeDeclarationsApiResponse = [];

		await Promise.all(
			catalogIds.map((catalogId) =>
				fetchGetAttributeDeclarations({ catalogId })
					.unwrap()
					.then((res) => (attributeDeclarations = [...attributeDeclarations, ...res]))
					.catch((err) => {
						errorHelper(
							t((l) => l.catalogs.records.declarationsListErr),
							err,
							notification
						);
						setCatalogRecordError(err);
					})
			)
		);

		if (attributeDeclarations) {
			const newColumns = !attributeDeclarations.length
				? []
				: [
						{
							title: t((l) => l.common.defaultNames.status),
							dataIndex: 'recStatus',
							key: 'recStatus',
							width: '175px',
						},
						{
							title: t((l) => l.common.defaultNames.name),
							dataIndex: 'displayName',
							key: 'displayName',
						},
						...Array.from(
							new Set(attributeDeclarations.map((decl) => decl.attribute.id))
						).map((atrId) => {
							const decl = attributeDeclarations.find(
								(item) => item.attribute.id === atrId
							);
							return {
								title: decl.attribute.displayName,
								dataIndex: decl.attribute.id,
								key: decl.id,
							};
						}),
						{
							title: <ColumnsAdjustmentWidget />,
							dataIndex: 'menu',
							key: 'menu',
							width: '50px',
						},
					];

			setCatalogRecordsColumns(newColumns);
			dispatch(setAttributeDeclarationsList(attributeDeclarations));

			const columns = newColumns.map((item) =>
				item.key === 'menu'
					? { ...item, title: t((l) => l.common.buttons.columnsSetup) }
					: item
			);
			dispatch(setCatalogRecordsOriginalColumns(columns));
		}
	};

	const getItems = async (
		catalogId: string,
		limit: number = 50,
		page: number = 1,
		includeSubCatalogs?: boolean
	) => {
		const searchValue = searchParams.get('searchValue');
		let hasRes = false;

		if (searchValue) {
			fetchSearch(searchValue);
			hasRes = true;
		} else if (catalogId) {
			const items = await fetchItems(catalogId, null, limit, page, includeSubCatalogs);

			if (Array.isArray(items)) {
				dispatch(setCatalogRecords(items));

				hasRes = true;
			} else {
				dispatch(removeCatalogRecords());
				hasRes = true;
			}
		}

		return hasRes;
	};

	const handleTableChange = (newPagination: TablePaginationConfig) =>
		dispatch(
			setPagination({
				current: newPagination.current,
				total: newPagination.total,
				pageSize: newPagination.pageSize,
				pageSizeOptions: newPagination.pageSizeOptions,
			})
		);

	const toggleSubordinatedCatalogsRecords = () => {
		const newState = !isSubordinatedCatalogsRecordsShown;

		setIsSubordinatedCatalogsRecordsShown(newState);

		localStorage.setItem(
			pathname,
			JSON.stringify({
				...JSON.parse(localStorage.getItem(pathname)),
				isSubordinatedCatalogsRecordsShown: newState,
			})
		);
	};

	useEffect(() => {
		getItems(
			catalogGroupId,
			pagination.pageSize,
			pagination.current,
			isSubordinatedCatalogsRecordsShown
		);
	}, [
		isSubordinatedCatalogsRecordsShown,
		catalogGroupId,
		pagination.pageSize,
		pagination.current,
	]);

	useEffect(() => {
		if (!catalogRecords?.length) return;
		const catalogIds = Array.from(new Set(catalogRecords?.map((item) => item.catalogId)));
		getAttributeDeclarations(catalogIds);
	}, [catalogRecords]);

	useEffect(() => {
		dispatch(removeCatalogRecords());
		setIsSubordinatedCatalogsRecordsShown(
			JSON.parse(localStorage.getItem(pathname))?.isSubordinatedCatalogsRecordsShown
		);
		if (catalogGroupId) {
			setCatalogRecordsColumns([]);
			setCatalogRecords([]);
			getItems(catalogGroupId).then(
				(res) => res && getAttributeDeclarations([catalogGroupId])
			);
		}
	}, [catalogGroupId]);

	useEffect(() => {
		const error = fetchGetItemsError || fetchGetAttributeDeclarationsError;
		if (!error) setCatalogRecordError(null);
	}, [fetchGetAttributeDeclarationsError, fetchGetItemsError]);

	useEffect(() => {
		if (ajustedColumns) {
			const newAjustedColumns = ajustedColumns.map((item) =>
				item.key === 'menu' ? { ...item, title: <ColumnsAdjustmentWidget /> } : item
			);
			setCatalogRecordsColumns(newAjustedColumns);
		}
	}, [ajustedColumns]);

	return {
		catalogGroupId,
		catalogRecordsDataSource: catalogRecords,
		catalogRecordsColumns,
		catalogRecordLoading: isGetItemsLoading || isDeclarationsLoading || isSearchItemsLoading,
		catalogRecordError,
		fetchItems,
		isGetItemsLoading,
		searchHandler,
		searchValue: searchParams.get('searchValue'),
		pagination: {
			...pagination,
			showTotal: tablePaginationShowTotal,
		} as PaginationProps,
		handleTableChange,
		isSubordinatedCatalogsRecordsShown,
		toggleSubordinatedCatalogsRecords,
	};
};
