import { RiEditLine, RiListSettingsLine, RiMore2Line } from '@remixicon/react';
import { Dropdown, Flex, Result, TableProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { CreateMeasureUnit } from 'features/metadata/Measures/createMeasureUnit';
import { DeleteMeasureUnit } from 'features/metadata/Measures/deleteMeasureUnit';

import {
	SearchMeasureUnit,
	useSearchMeasureUnit,
} from 'features/metadata/Measures/searchMeasureUnit';
import { useGetCurrentMeausureGroup } from 'entities/metadata/measures';

import { routes } from 'shared/config';
import { DetailFooter, DropdownLink, WorkTable } from 'shared/ui';

interface DataType {
	id: string;
	key: string;
	name: string;
	code: string;
	factor: string;
	formula: string;
	isBasic: string;
}

const DetailMeasuresGroupWidget = () => {
	const [dataSource, setDataSource] = useState<DataType[]>(null);
	const [selectedRows, setSelectedRows] = useState<DataType[]>(null);
	const { currentMeasureGroup, loading, error } = useGetCurrentMeausureGroup();
	const { isLoading: isSearchLoading } = useSearchMeasureUnit();
	const navigate = useNavigate();

	const customCallback = () => navigate(`/${routes.metadata.main}/${routes.metadata.measures}`);
	const columns: TableProps<DataType>['columns'] = [
		{
			title: 'Наименование',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Обозначение',
			dataIndex: 'code',
			key: 'code',
		},
		{
			title: 'Коэффициент пересчета',
			dataIndex: 'factor',
			key: 'factor',
		},
		{
			title: 'Базовая ЕИ',
			dataIndex: 'isBasic',
			key: 'isBasic',
		},
		{
			title: <RiListSettingsLine size={16} />,
			key: 'action',
			width: 16,
			render: (_, record) => (
				<Dropdown
					menu={{
						items: [
							{
								label: (
									<DropdownLink
										title="Редактировать"
										icon={<RiEditLine size={16} />}
										callback={function (): void {
											navigate(`unit/${record.id}`);
										}}
									/>
								),
								key: '1',
							},
							{
								label: <DeleteMeasureUnit isDropdown unitId={[record.id]} />,
								key: '2',
							},
						],
					}}
					trigger={['click']}
					placement="bottomRight"
				>
					<RiMore2Line
						size={16}
						style={{ cursor: 'pointer' }}
						onClick={(e) => e.stopPropagation()}
					/>
				</Dropdown>
			),
		},
	];

	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
			setSelectedRows(selectedRows);
		},
	};

	useEffect(() => {
		if (currentMeasureGroup) {
			setSelectedRows(null);
			setDataSource(
				currentMeasureGroup?.childrenMeasures?.map((unit) => ({
					id: unit.id,
					name: unit.displayName,
					code: unit.shortName,
					factor: unit.isDefault ? '1' : `${unit.coefficient}`,
					formula: unit.formula,
					revFormula: unit.inverseFormula,
					isBasic: unit.isDefault ? 'Да' : 'Нет',
					key: `${unit.id}`,
				}))
			);
		}
	}, [currentMeasureGroup]);

	if (error)
		return (
			<Result
				title={'Ошибка при получении единиц измерения!'}
				subTitle={`Текст ошибки: ${JSON.stringify(error)}`}
			/>
		);

	return (
		<Flex vertical gap={24}>
			<Flex vertical gap={12}>
				<SearchMeasureUnit />
				<Flex gap={6}>
					<CreateMeasureUnit />
				</Flex>
			</Flex>
			<WorkTable
				dataSource={dataSource}
				columns={columns}
				loading={loading || isSearchLoading}
				noFilters
				rowSelection={rowSelection}
				style={{ marginBottom: 65 }}
				pagination={{
					showTotal: (total) => `Всего: ${total}`,
					pageSizeOptions: [10, 20, 30],
					showSizeChanger: true,
					size: 'small',
				}}
				onRow={(row) => {
					return {
						onClick: () => {
							navigate(`unit/${row.id}`);
						},
					};
				}}
				scroll={{ x: 'max-content', y: 'calc(100vh - 250px)' }}
			/>
			<DetailFooter customHandleBack={customCallback}>
				<DeleteMeasureUnit unitId={selectedRows?.map((item) => item.id)} />
			</DetailFooter>
		</Flex>
	);
};

export const DetailMeasuresGroup = React.memo(DetailMeasuresGroupWidget);
