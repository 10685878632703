import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClassifierItemDto, ClassifierItemTreeNode } from 'shared/api/generatedApi/mdmgApi';
import { PaginationModel } from 'shared/models';

export type ClassifierItem = ClassifierItemDto & { children?: Array<ClassifierItem> };

interface IclassifierItemsListState {
	classifierItemsList: Array<ClassifierItemDto>;
	currentClassifierItem: ClassifierItemDto;
	pagination: PaginationModel;
}

const initialState: IclassifierItemsListState = {
	classifierItemsList: null,
	currentClassifierItem: null,
	pagination: {
		pageSizeOptions: [20, 50, 100, 200],
		current: 1,
		pageSize: 20,
		total: 0,
	},
};

const classifierItemsSlice = createSlice({
	name: 'classifierItems',
	initialState,
	reducers: {
		setClassifierItems(state, action: PayloadAction<ClassifierItemDto[]>) {
			state.classifierItemsList = [...action.payload];
		},
		setCurrentClassifierItem(state, { payload }: PayloadAction<ClassifierItemDto>) {
			state.currentClassifierItem = payload;
			if (!!state.classifierItemsList && state.classifierItemsList.length && !!payload) {
				state.classifierItemsList = state.classifierItemsList.map((item) => {
					if (item?.id === payload.id) return payload;
					return item;
				});
			}
		},
		editCurrentClassifierItem(state, { payload }: PayloadAction<ClassifierItemDto>) {
			state.currentClassifierItem = { ...state.currentClassifierItem, ...payload };
			if (state.classifierItemsList && state.classifierItemsList.length) {
				const updateGroupById = (
					tree: ClassifierItem[],
					updatedItem: ClassifierItem
				): ClassifierItem[] => {
					return tree.map((item) => {
						if (item.id === updatedItem?.id) {
							return updatedItem;
						}
						if (
							'children' in item &&
							Array.isArray(item.children) &&
							item.children.length
						) {
							return {
								...item,
								children: updateGroupById(item.children, updatedItem),
							};
						}
						return item;
					});
				};

				state.classifierItemsList = updateGroupById(state.classifierItemsList, payload);
			}
		},

		updateClassifierChildTreeItem(
			state,
			{ payload }: PayloadAction<{ item: ClassifierItemDto; parentItemId: string }>
		) {
			const updateCatalogGroupById = (
				arr: ClassifierItemTreeNode[],
				parentId: string,
				node: ClassifierItemTreeNode
			): ClassifierItemTreeNode[] => {
				return arr
					? arr.map((item) => {
							if (item.id === parentId) {
								return {
									...item,
									parent: true,
									isLeaf: false,
									children: item.children ? [...item.children, node] : [node],
								};
							}

							if (item.children) {
								return {
									...item,
									children: updateCatalogGroupById(item.children, parentId, node),
								};
							}
							return item;
						})
					: [];
			};

			state.classifierItemsList = updateCatalogGroupById(
				state.classifierItemsList,
				payload.parentItemId,
				payload.item
			);
		},
		addNewClassifierItem(state, action: PayloadAction<ClassifierItemDto>) {
			state.classifierItemsList = [
				...(state.classifierItemsList && state.classifierItemsList),
				action.payload,
			];
		},
		deleteClassifierItem(state, { payload }: PayloadAction<string>) {
			state.classifierItemsList = state.classifierItemsList.filter(
				(Classifier) => Classifier.id !== payload
			);
		},
		deleteClassifierItemById(state, { payload }: PayloadAction<string>) {
			const deleteCatalog = (
				tree: ClassifierItemTreeNode[],
				id: string
			): ClassifierItemTreeNode[] => {
				const data = tree.filter((item) => {
					if (item.id !== id && item.children?.length) {
						return deleteCatalog(item.children, id);
					}
					return item.id !== id;
				});

				return data.map((item) => {
					if (item.children) {
						return {
							...item,
							children: deleteCatalog(item.children, id),
						};
					}
					return item;
				});
			};

			state.classifierItemsList = deleteCatalog(state.classifierItemsList, payload);
		},
		removeCurrentClassifierItem(state) {
			state.currentClassifierItem = null;
		},
		removeClassifierItems(state) {
			state.classifierItemsList = null;
		},
		setPagination(state, action: PayloadAction<PaginationModel>) {
			state.pagination = action.payload;
		},
		decreasePaginationTotal(state) {
			state.pagination = {
				...state.pagination,
				total: state.pagination.total - 1,
			};
		},
	},
});

export const {
	setClassifierItems,
	setCurrentClassifierItem,
	updateClassifierChildTreeItem,
	editCurrentClassifierItem,
	deleteClassifierItemById,
	addNewClassifierItem,
	deleteClassifierItem,
	removeCurrentClassifierItem,
	removeClassifierItems,
	setPagination,
	decreasePaginationTotal,
} = classifierItemsSlice.actions;

export const classifierItemsReducer = classifierItemsSlice.reducer;
