import {
	RiDeleteBinLine,
	RiEditLine,
	RiListSettingsLine,
	RiExpandUpDownLine,
	RiInformationLine,
} from '@remixicon/react';
import { Flex, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AddNewRow } from 'features/metadata/ConstraintTables/addNewRow';
import { AssignColumn } from 'features/metadata/ConstraintTables/assignColumn';
import { DeleteRowModal } from 'features/metadata/ConstraintTables/deleteRow';
import { useConstraintTableDetails } from 'entities/metadata/constraintTables';
import { colors } from 'shared/styles';
import { DetailFooter, DropdownLink, Hint, Placeholder, WorkTable } from 'shared/ui';
import AppDropdown from 'shared/ui/components/AppDropdown';
import { aStyles } from 'shared/ui/components/DropdownButtons/DropdownButtonsStyles';

interface IDataSource {
	key: string;
	[key: string]: React.ReactNode;
}

export const ConstraintTableDetails = () => {
	const [columns, setColumns] = useState<ColumnsType<IDataSource> | null>(null);
	const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [rowsToDelete, setRowsToDelete] = useState<string[]>([]);

	const { isTableInfoLoading, tableColumns, restrictionTableItems } = useConstraintTableDetails();

	const navigate = useNavigate();

	const dataSource = restrictionTableItems?.map((item) => ({
		key: item.id,
		...Object.fromEntries(
			Object.entries(item.values).map(([key, value]) => [
				key,
				(value as Array<string>).join(', '),
			])
		),
	}));

	const handleEditRow = (id: string) => {
		navigate(`${id}`);
	};

	useEffect(() => {
		if (tableColumns) {
			setColumns([
				...tableColumns.map((col, i) => ({
					title:
						i !== 0 ? (
							col.displayName
						) : (
							<Flex align="center" gap={12}>
								{col.displayName}
								<Hint
									title={
										<Flex vertical gap={6}>
											<div>
												«+» — Атрибут должен быть обязательно заполнен
											</div>
											<div>«*»— Значение атрибута может быть любым</div>
											<div>
												«*+» — Значение атрибута может быть любым, но
												обязательно заполненным, т.е. ячейка не может
												остаться пустой
											</div>
										</Flex>
									}
								>
									<RiInformationLine size={16} color={colors.grayIcon} />
								</Hint>
							</Flex>
						),
					dataIndex: col.id,
					key: col.id,
					sorter: true,
					sortIcon: () => <RiExpandUpDownLine size={16} />,
					showSorterTooltip: false,
				})),
				{
					title: <RiListSettingsLine size={16} />,
					key: 'action',
					width: 16,
					render: (_, record) => (
						<AppDropdown
							items={[
								{
									label: (
										<DropdownLink
											title="Редактировать"
											icon={<RiEditLine size={16} />}
											callback={() => handleEditRow(record.key)}
										/>
									),
									key: '1',
								},
								{
									label: (
										<a
											onClick={() => handleDeleteModalOpen(record.key)}
											style={aStyles}
										>
											<RiDeleteBinLine size={16} />
											Удалить
										</a>
									),
									key: '2',
								},
							]}
						/>
					),
				},
			]);
		}
	}, [tableColumns]);

	const handleDeleteModalClose = () => {
		setRowsToDelete(selectedRows as string[]);
		setIsDeleteModalOpen(false);
	};
	const handleDeleteModalOpen = (soloRowId?: string) => {
		if (soloRowId) setRowsToDelete([soloRowId]);
		setIsDeleteModalOpen(true);
	};
	const handleDeleteModalOk = () => {
		setSelectedRows([]);
		setRowsToDelete([]);
		setIsDeleteModalOpen(false);
	};

	const rowSelection = {
		onChange: (selectedRowKeys: React.Key[]) => {
			setSelectedRows(selectedRowKeys);
			setRowsToDelete(selectedRowKeys as string[]);
		},
		selectedRowKeys: selectedRows,
		getCheckboxProps: (record: { [key: string]: string }) => ({
			disabled: record.name === 'Disabled User',
			name: record.name,
		}),
	};
	return (
		<>
			<Flex gap={6}>
				<AddNewRow />
			</Flex>

			<AssignColumn />

			<div style={{ width: '100%', height: '100%' }}>
				{columns?.length > 1 || isTableInfoLoading ? (
					<WorkTable
						columns={columns}
						loading={isTableInfoLoading}
						dataSource={dataSource}
						rowSelection={rowSelection}
						noFilters
						style={{ marginBottom: selectedRows?.length > 0 ? 65 : 0 }}
						onRow={(r) => ({
							onClick: () => navigate(`${r.key}`),
						})}
						scroll={{ x: 'max-content', y: 'calc(100vh - 250px)' }}
					/>
				) : (
					<Placeholder title="Вы еще не выбрали ни одного столбца для ограничительной таблицы" />
				)}
			</div>

			{selectedRows?.length > 0 && (
				<DetailFooter isNavHidden>
					<Button onClick={() => handleDeleteModalOpen()} icon={<RiDeleteBinLine />}>
						Удалить выбранные
					</Button>
				</DetailFooter>
			)}

			<DeleteRowModal
				isOpen={isDeleteModalOpen}
				onClose={handleDeleteModalClose}
				onOk={handleDeleteModalOk}
				rowsToDelete={rowsToDelete}
			/>
		</>
	);
};
